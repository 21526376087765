body {
    background-color: #f4f4f4;
    font-size: 14px;
}



.brd-highlight-bottom {
    border-bottom-color: #009acb !important;
}

.clickable {
    cursor: pointer;
}

.text-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}